<template>
  <div class="content_body" v-loading="loading">
    <div class="nav_header">
      <el-row>
        <el-col :span="20">
          <el-form :inline="true" size="small">
            <el-form-item label="话术描述">
              <el-input v-model="Name" placeholder="输入话术描述搜索" clearable @clear="handleSearch" @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
            <el-form-item label="话术类目">
              <el-cascader @change="getSearchResult" @clear="getSearchResult" :options="classifyList" :show-all-levels="true" clearable filterable :props="cascaderProps" v-model="PCategoryID" placeholder="请选择话术类目"></el-cascader>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="handleSearch" v-prevent-click>搜索</el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="4" class="text_right">
          <el-button type="primary" size="small" @click="showAddDialog" v-prevent-click>新增</el-button>
        </el-col>
      </el-row>
    </div>

    <div class="martp_10">
      <el-table size="small" :data="tableData">
        <el-table-column prop="Name" label="话术描述"></el-table-column>
        <el-table-column prop="ArtSpeakCategoryName" label="话术类目"></el-table-column>
        <el-table-column prop="Content" label="话术内容" show-overflow-tooltip tooltip-effect="light" min-width="140px"></el-table-column>
        <el-table-column label="操作" width="145">
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="showEditDialog(scope.row)" v-prevent-click>编辑</el-button>
            <el-button v-show="isDelete" type="danger" size="small" @click="DeleteDialog(scope.row)" v-prevent-click>删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pad_15 text_right">
        <el-pagination background v-if="paginations.total > 0" @current-change="handleCurrentChange" :current-page.sync="paginations.page" :page-size="paginations.page_size" :layout="paginations.layout" :total="paginations.total"></el-pagination>
      </div>
    </div>
    <!--添加、编辑弹出框-->
    <el-dialog title="话术信息" :visible.sync="AddDialogVisible" width="860px" destroy-on-close>
      <el-form :model="ruleForm_add" :rules="rules_add" ref="ruleForm_add" label-width="120px" size="small">
        <el-row>
          <el-col :span="12">
            <el-form-item label="话术描述" prop="Name">
              <el-input placeholder="请输入话术描述" v-model="ruleForm_add.Name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="话术类目" prop="ArtSpeakCategoryID">
              <el-cascader :options="classifyList" :show-all-levels="true" clearable filterable :props="cascaderProps" v-model="ruleForm_add.ArtSpeakCategoryID" placeholder="请选择话术类目"></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="22">
            <el-form-item label="话术内容" prop="Content">
              <el-input type="textarea" rows="10" placeholder="请输入话术内容" v-model="ruleForm_add.Content"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="resetAddForm" size="small" v-prevent-click>取 消</el-button>
        <el-button type="primary" @click="submitAddForm" size="small" v-prevent-click>保 存</el-button>
      </div>
    </el-dialog>
    <!--添加分类弹出框-->
    <el-dialog :title="dialogTitle_add" :visible.sync="addTypeDialogVisible" width="30%">
      <el-form :model="addTypeForm" :rules="addTypeFormRule" ref="addTypeFormRule" label-width="120px" size="small">
        <el-form-item label="分类名称" prop="name" v-if="dialogType == 'classify'">
          <el-input v-model="addTypeForm.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="单位名称" prop="unitName" v-if="dialogType == 'unit'">
          <el-input v-model="addTypeForm.unitName" placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetTypeForm" size="small" v-prevent-click>取 消</el-button>
        <el-button type="primary" @click="submitTypeForm" size="small" v-prevent-click>确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import API from "../../../api/CRM/ArtSpeak/artSpeakInfo";
import permission from "@/components/js/permission.js";
export default {
  name: "ArtSpeakInfo",
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.isDelete = permission.permission(
        to.meta.Permission,
        "CRM-ArtSpeak-ArtSpeakInfo-Delete"
      );
      vm.getProductCategory();
    });
  },
  data() {
    return {
      isDelete: true,
      loading: false,
      Active: true,
      modalLoading: false,
      dialogVisible: false, // 批量设置弹出框状态
      AddDialogVisible: false, // 添加或编辑信息弹出框状态
      addTypeDialogVisible: false, // 添加、编辑弹出框状态
      isEditInfo: false, // 是否编辑 编辑状态下 包装单位、话术条码 不可修改
      Name: "",
      PCategoryID: "",
      ProductBrandID: "",
      tableData: [], // 表格数据
      modalTitle: "新增",
      multipleSelection: [],
      commandType: "",
      dialogTitle: "", // 批量设置弹出框标题
      classifyList: [], // 分类列表
      dialogType: "", // 判断添加分类弹出框类别
      dialogTitle_add: "", // 添加分类弹出框标题
      unitList: [], // 单位列表
      routerType: "", // 添加、编辑按钮事件判断
      ruleForm: {
        classify: "",
        purpose: "",
        effectiveness: "",
      }, // 批量设置弹出框表单
      rules: {
        classify: [{ required: true, message: "请输入分类", trigger: "blur" }],
        purpose: [{ required: true, message: "请输入用途", trigger: "blur" }],
        effectiveness: [
          { required: true, message: "请输入有效性", trigger: "blur" },
        ],
      }, // 批量设置弹出框表单验证规则
      ruleForm_add: {
        Name: "",
        Content: "",
        ArtSpeakCategoryID: "",
        ID: "",
      }, // 添加、编辑弹出框表单数据
      rules_add: {
        Name: [
          { required: true, message: "请输入话术名称", trigger: "blur" },
          { max: 30, message: "最多允许30个汉字(60个字符)", trigger: "blur" },
        ],
        ArtSpeakCategoryID: [
          { required: true, message: "请选择分类", trigger: "blur" },
        ],
        Content: [
          { required: true, message: "请输入话术内容", trigger: "blur" },
        ],
      }, // 添加、编辑弹出框表单验证规则
      // BarCode: [{ required: true, message: "请输入话术条码", trigger: "blur" }],
      addTypeForm: {
        name: "",
        unitName: "",
      }, // 添加分类弹出框表单数据
      addTypeFormRule: {
        name: [{ required: true, message: "请输入分类名称", trigger: blur }],
        unitName: [
          { required: true, message: "请输入单位名称", trigger: blur },
        ],
      }, // 添加分类弹出框表单验证规则
      paginations: {
        page: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 12, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      }, //需要给分页组件传的信息
      cascaderProps: {
        value: "ID",
        label: "Name",
        children: "Child",
        emitPath: false,
        expandTrigger: "hover",
      }, // 级联选择器配置项
      productBrandList: [], // 话术品牌列表
    };
  },
  methods: {
    // 搜索
    getSearchResult() {
      var that = this;
      that.paginations.page = 1;
      that.getProductList();
    },
    // 数据显示
    handleSearch: function () {
      var that = this;
      that.paginations.page = 1;
      that.getProductList();
    },
    // 获取话术分类列表
    getProductCategory: function () {
      var that = this;
      that.loading = true;
      // var params = {
      //   Name: "",
      //   Active: true,
      // };
      API.artSpeakCategory()
        .then((res) => {
          if (res.StateCode == 200) {
            that.classifyList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 获取话术列表
    getProductList: function () {
      var that = this;
      that.loading = true;
      var params = {
        Name: that.Name,
        ArtSpeakCategoryID: that.PCategoryID,
        // Active: that.Active,
        PageNum: that.paginations.page,
        // ProductBrandID:that.ProductBrandID,
      };
      API.getProduct(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.tableData = res.List;
            that.paginations.page_size = res.PageSize;
            that.paginations.total = res.Total;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 上下分页
    handleCurrentChange(page) {
      var that = this;
      that.paginations.page = page;
      that.getProductList();
    },
    // 新增
    showAddDialog: function () {
      var that = this;
      that.isEditInfo = false;
      that.ruleForm_add = {
        Name: "",
        ID: "",
        ArtSpeakCategoryID: "",
        Content: "",
      };
      that.AddDialogVisible = true;
      that.routerType = "add";
    },
    // 编辑
    showEditDialog: function (row) {
      var that = this;
      that.isEditInfo = true;
      that.AddDialogVisible = true;
      that.routerType = "update";
      var tableInfo = row;
      that.Id = tableInfo.ID;
      that.ruleForm_add = tableInfo;
    },
    // 表格选择事件
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 批量设置点击事件
    handleSelect(val) {
      var that = this;
      if (that.multipleSelection.length > 0) {
        that.dialogVisible = true;
        that.commandType = val;
        switch (val) {
          case "classify":
            that.dialogTitle = "分类";
            break;
          case "purpose":
            that.dialogTitle = "用途";
            break;
          case "effectiveness":
            that.dialogTitle = "有效性";
            break;
        }
      } else {
        that.$message.error("请先选择话术信息");
      }
    },
    // 批量设置弹出框确定事件
    submitForm() {
      var that = this;
      that.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          that.dialogVisible = false;
        }
      });
    },
    // 表单取消事件
    resetForm() {
      var that = this;
      that.$refs["ruleForm"].resetFields();
      that.dialogVisible = false;
    },
    // 添加、编辑部分
    // 新增话术
    createProduct: function () {
      var that = this;
      that.loading = true;
      var params = that.ruleForm_add;
      API.createProduct(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.AddDialogVisible = false;
            that.$refs["ruleForm_add"].resetFields();
            that.getProductList();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 编辑话术
    updateProduct: function () {
      var that = this;
      that.loading = true;
      var params = that.ruleForm_add;
      params.ID = that.Id;
      API.updateProduct(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.AddDialogVisible = false;
            that.$refs["ruleForm_add"].resetFields();
            that.getProductList();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 创建话术类别
    createProductCategory: function () {
      var that = this;
      that.loading = true;
      var params = {
        Name: that.addTypeForm.name,
      };
      APICategory.createProductCategory(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.addTypeDialogVisible = false;
            that.getProductCategory();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 表单点击保存按钮
    submitAddForm() {
      var that = this;
      this.$refs["ruleForm_add"].validate((valid) => {
        if (valid) {
          if (that.routerType == "update") {
            that.updateProduct();
          } else if (that.routerType == "add") {
            that.createProduct();
          }
        }
      });
    },
    // 表单点击取消按钮
    resetAddForm() {
      var that = this;
      that.AddDialogVisible = false;
    },
    // 添加分类表单确认按钮
    submitTypeForm() {
      var that = this;
      this.$refs["addTypeFormRule"].validate((valid) => {
        if (valid) {
          if (that.dialogType == "classify") {
            that.createProductCategory();
          } else {
            that.createUnit();
          }
        }
      });
    },
    // 添加分类表单取消按钮
    resetTypeForm() {
      var that = this;
      that.$refs["addTypeFormRule"].resetFields();
      that.addTypeDialogVisible = false;
    },
    // 添加分类
    addClassify(type) {
      var that = this;
      that.dialogType = type;
      if (type == "classify") {
        that.dialogTitle_add = "添加分类";
      } else {
        that.dialogTitle_add = "添加单位";
      }
      that.addTypeDialogVisible = true;
    },
    // 删除话术
    DeleteDialog(row) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          API.artSpeakDelete({ ID: row.ID }).then((res) => {
            if (res.StateCode == 200) {
              this.$message.success("已删除");
              this.getProductList();
            }
          });
        })
        .catch(() => {
          console.log("fdhf");
        });
    },
  },
  mounted() {
    var that = this;
    that.getProductList(); //列表
    that.getProductCategory(); //分类
    that.isDelete = permission.permission(
      that.$route.meta.Permission,
      "CRM-ArtSpeak-ArtSpeakInfo-Delete"
    );
  },
};
</script>

<style lang="scss">
</style>

